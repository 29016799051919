$(function() {
  let scrollTop = 0
  let winWidth = window.innerWidth
  let winCenter = parseInt(window.innerHeight*0.7)
  let fadeInPoint = []
  let mode = getScreenMode(winWidth)
  
  if (winWidth > 1024) {
    headerPcHandler()
  } else {
    headerMobileHandler()
  }
  if (window.location.pathname.split('/')[1] === 'en-US') {
    $('#btnLang button[data-lang="en"]').addClass('active')
  } else {
    $('#btnLang button[data-lang="ko"]').addClass('active')
  }
  $(document).ready(function() {
    fadeAction()
  })

  $(window).on('load', function () {
    winWidth = window.innerWidth
    fadeAction()
  })

  $(window).resize(function() {
    winWidth = window.innerWidth

    let newMode = getScreenMode(winWidth)
    if (mode !== newMode) {
      location.reload()
    }
  })
  
  $(window).scroll(function () {
    $('#header').toggleClass('down', scrollTop < $(window).scrollTop() && $(window).scrollTop() > 30)
    $('#header').toggleClass('reachTop', scrollTop < 31)
    scrollTop = $(window).scrollTop()
  })

  $('#btnLang button').on('click', function () {
    if ($(this).hasClass('active')) return false
    const lang = $(this).data('lang') === 'en' ? '/en-US' : ''
    const currentLocation = window.location.pathname.split('/').pop()
    window.location.href = `${lang}/${currentLocation}`
  })

  function getScreenMode (winW) {
    let mode = 'pc'
    if (winW > 1024) {
      mode = 'pc'
    } else if (winW > 768) {
      mode = 'tablet'
    } else {
      mode = 'mobile'
    }
    return mode
  }

  function headerPcHandler () {
    scrollTop = $(window).scrollTop()
    
    $('#header').toggleClass('reachTop', scrollTop < 31)
    $('#header').removeClass('open')

    $(document).on('mouseenter', '#header', function () {
      $(this).addClass('hover')
    })

    $(document).on('mouseleave', '#header', function () {
      $(this).removeClass('hover')
    })

    $(document).on('mouseenter', '#header .depthWrap', function () {
      $('#header').addClass('depth2on')
      $(this).addClass('on')
    })

    $(document).on('mouseleave', '#header .depthWrap', function () {
      $('#header').removeClass('depth2on')
      $(this).removeClass('on')
    })
  }

  function headerMobileHandler () {
    scrollTop = $(window).scrollTop()

    $('#header').toggleClass('reachTop', scrollTop < 31)
    $('#header').removeClass('hover on')

    $(document).off('mouseenter', '#header')
    $(document).off('mouseleave', '#header')

    $('#header .btnToggle').click(function () {
      if ($('#header').hasClass('open')) {
        $('body').css({overflow: 'auto'})
      } else {
        $('body').css({overflow: 'hidden'})
      }
      $('#header').toggleClass('open')
    })
  }

  // function resetFadeInPoint () {
  //   winCenter = parseInt(window.innerHeight*0.7)
  //   fadeInPoint = []
  //   $('.fade').each(function () {
  //     $(this).removeClass('in')
  //     fadeInPoint.push($(this).offset().top-winCenter)
  //   })
  //   fadeAction()
  // }

  function fadeAction () {
    $('.fade').addClass('in')
    // let i, item
    // scrollTop = $(window).scrollTop()
    // for (i = 0; i < fadeInPoint.length; i++) {
    //   item = $('.fade').get(i)
    //   $(item).toggleClass('in', scrollTop > fadeInPoint[i])
    // }
  }
})